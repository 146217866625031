@import "Colors.scss";
@import "Constants.scss";

.root {
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;

  .activateButton {
    position: absolute;
    right: 6px;
    top: 8px;
    visibility: hidden;
  }

  &:hover {
    .activateButton {
      visibility: visible;
    }
  }

  &.isHidden:not(&.hasTask) {
    span {
      font-weight: 400;
    }

    opacity: 0.6;
  }

  &.hasTask {
    @media(max-width: $vertical-phone-max-width) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h6.measurementValue {
        font-size: 10px;
        font-weight: normal !important;
      }
      .measurementName {
        font-size: 11px;
      }
    }
  }

  &.hasNoValue {
    opacity: 0.6;
  }

  &.isCounting {
    background-color: $highlight;
  }

  .measurementName {
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;

    &.hasSecondaryText {
      -webkit-line-clamp: 1;
    }
  }
}

.verifiedIcon {
  color: $action;
  width: 19px;
  height: 13px;
}

.rootEditMode {
  flex: 1;

  &.hasTask {
    min-width: 300px;

    @media(max-width:$vertical-phone-max-width) {
      min-width: 240px;

      .measurementName {
        flex: 1 !important;
      }
    }
  }

  //margin-top: -4px;
  //margin-bottom: -4px;
  z-index: 2;
  background-color: white;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  >.nameField,
  >.editTools {
    width: 100%;
    flex: none !important;
  }

  .measurementName {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 13.5px;
    flex: 1 0;
  }

  .select {}

  .textField {
    flex: 1;
  }

  .combobox {
    min-width: 150px;

    // bad
    >div>div>svg {
      margin-left: 40%;
    }
  }

  .textField,
  .select {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 5px;
  }

  .textFieldUnit {
    margin-right: 0;
  }

  .quantityAndUnitFields {
    margin-top: 5px;
  }

  .decimalField:not(.hasVariables) {
    input {
      text-align: right;
    }
  }
}

.measurementValue {
  text-align: right;
  white-space: nowrap;
}

.avatarShapePreview {
  background-color: white !important;
  border: 1px solid $border !important;

  >div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.descriptionInput {
  flex: 1;
}

.textFieldNoLabel input {
  padding: 8px 8px 6px;
}

.editTools {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
}

.detailsSeparator {
  width: 10px;
}

.flexSeparator {
  flex: 1;
}

.detailsButton {
  color: $primary !important;
}

.acceptButton {
  color: $green;
}

.cancelButton {
  color: $red;
}

.removeButton {}

.quantityAndUnitFields {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  //flex-wrap: nowrap;
}

.formulaValue {
  display: flex;
}

.sumIcon {
  color: $gray3;
  margin-right: -3px;
}

.measurementText {
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media(max-width:$vertical-phone-max-width) {
      font-size:10px;
    }
  }

  height: 100%;
}

.measurementAndColor {
  display: flex;
  align-items: center;
  //flex-wrap: nowrap;

  height: 100%;
}

.colorButton {
  margin-left: 4px;
}